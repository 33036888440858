.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.home-heading {
  @apply no-scrollbar mx-auto flex max-w-6xl flex-row items-center gap-2 md:gap-4 lg:gap-6 overflow-x-scroll p-2;
}
.home-product-section {
  @apply mt-2 grid grid-cols-2 gap-2 md:mt-5 md:grid-cols-3 md:gap-5 xl:grid-cols-3;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.highlighted-item {
  background-color: #f0f0f0; /* Change the background color as desired */
  cursor: pointer; /* Change the cursor style on hover */
}

.search-scrollbar::-webkit-scrollbar {
  width: 5px;
  
}


.search-scrollbar::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-wrapper select {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
}

.dropdown-wrapper select option {
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
}

/* Custom scrollbar styling */
.overflow-container::-webkit-scrollbar {
  width: 5px; /* Scrollbar width */
}

.overflow-container::-webkit-scrollbar-track {
  background: #e0e0e0; /* Track color */
  border-radius: 5px; /* Rounded scrollbar track */
}

.overflow-container::-webkit-scrollbar-thumb {
  background-color: #4caf50; /* Scrollbar thumb color */
  border-radius: 5px; /* Rounded scrollbar thumb */
  border: 2px solid transparent; /* Extra padding around thumb */
}

.overflow-container::-webkit-scrollbar-thumb:hover {
  background-color: #388e3c; /* Change thumb color on hover */
}

/* Add some beautiful typography to content */
.overflow-container p {
  line-height: 1.6;
  font-size: 16px;
  color: #333;
}

/* Optional: Add a fade effect at the bottom to indicate more content */
.overflow-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
}
